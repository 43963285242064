import styled from "styled-components"

export const BasicTextModuleStyles = styled.section`
  .container {
    @media (min-width: 768px) {
      > div {
        width: 66.666%;
        max-width: 700px;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        a {
          transition: color 0.3s ease;
        }
      }
    }

    ul {
      margin-bottom: var(--gap);

      @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }

    li {
      margin-top: 5px;
      margin-bottom: 5px;

      padding: 0px 0 0px 44px;
      background-image: url("/wood-icon-1.png");
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 40px;

      a {
        color: var(--bodyColor);
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 700;

        span {
          color: var(--primary);
        }
      }

      &:hover,
      &:focus {
        a {
          color: var(--primary);
        }
      }
    }

    li::marker {
      font-size: 1.5rem; /* You can use px, but I think rem is more respecful */
    }
  }
`
