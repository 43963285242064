import * as React from "react"
import Button from "../Button/Button"
import { BasicTextModuleStyles } from "./BasicTextModuleStyles"
import { Link } from "gatsby"

const BasicTextModule = ({ id, title, content, link, linkText, listItems }) => {
  return (
    <BasicTextModuleStyles className="section">
      <div className="container container__tight" id={id}>
        <div>
          {title && <h2>{title}</h2>}
          {content && <p style={{ marginBottom: "60px" }}>{content}</p>}

          {listItems && (
            <ul>
              {listItems.map((item, index) => {
                return <li key={index}>{item}</li>
              })}
            </ul>
          )}
          {/* <Button text={linkText} as={Link} to={link} /> */}
        </div>
      </div>
    </BasicTextModuleStyles>
  )
}

export default BasicTextModule
